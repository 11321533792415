import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  namespaced: true,
  state: {
    pendingOrders: []
  },
  getters: {},
  mutations: {
    ...makeSetters([
      'pendingOrders'
    ])
  },
  actions: {
    loadPendingOrders ({commit, rootGetters}) {
      const url = '/api/v2/zone-maps/{orgUnitId}/pending-orders'

      return smarterGet(url, {
        id: 'frs.pendingZoneMapOrders.getPendingZoneMapOrders',
        inputs: {
          orgUnitId: () => rootGetters['fieldRecordSystem/orgUnitId']
        },
        expiry: 60,
        sharedCache: true,
        onResult (pendingOrders) {
          commit('setPendingOrders', pendingOrders)
        }
      })
    }
  }
}
